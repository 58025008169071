import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpointLarge } from '../../styles/breakpoints';

const CollapsibleStyled = styled.div`
  padding-bottom: 8px;
  margin-bottom: 8px;
`;
const Title = styled.h2`
  margin:20px 0 0 0;
  position: relative;
  font-size: 1.4rem;
  padding-left: 4rem;
  color: ${props =>
    props.isOpen ? props.theme.gray : props.theme.gray};
  font-weight: ${props =>
      props.isOpen ? '800' : '500'};
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
  cursor: pointer;
  @media (min-width: ${breakpointLarge}) {
    font-size: 1.6rem;
  }
`;
const Icon = styled.i`
  position: absolute;
  left: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.isOpen ? props.theme.blue : props.theme.gray200};
  
  &:before {
    content: ' ';
    border-radius: 0.07rem;
    position: absolute;
    width: ${props => (props.isOpen ?  '0.13rem' :'0.09rem' )};
    height: 0.73rem;
    background-color: ${props =>
      props.isOpen ? props.theme.white : props.theme.gray};
    transform: rotate(${props => (props.isOpen ? '-45deg' : '45deg')});
    transition: transform 0.25s ease, width 0.25s ease-out;
    top: ${props => (props.isOpen ?  '0.42rem':'0.75rem')};
    left: ${props => (props.isOpen  ? '0.98rem':'1.21rem' )};

  }
  &:after {
    content: ' ';
    border-radius: 0.07rem;
    position: absolute;
    width: ${props => (props.isOpen ?  '0.13rem' :'0.09rem' )};
    height: 0.73rem;
    background-color: ${props =>
      props.isOpen ? props.theme.white : props.theme.gray};
    transform: rotate(${props => (props.isOpen ? '45deg' : '-45deg')});
    top: ${props => (props.isOpen ? '0.87rem':'0.75rem' )};
    left: ${props => (props.isOpen  ? '0.98rem':'0.74rem')};
    transition: all 0.25s ease;
  }
`;
const Content = styled.div`
  padding-left: 4rem;
  padding-right: 0.9rem;
  font-size: 1rem;
  color: ${props => props.theme.gray};
  max-height: ${props => (props.isOpen ? '100%' : '0')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s ease;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
  img {
    max-width: 100%;
  }
`;

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CollapsibleStyled>
    
      <Title isOpen={isOpen} onClick={() => setIsOpen(prevValue => !prevValue)}>
      <Icon isOpen={isOpen} />
        {title}
      </Title>
      <Content isOpen={isOpen}>{children}</Content>
    </CollapsibleStyled>
  );
};

export default Collapsible;
